import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { media } from '../../utils/media';

const CustomForm = ({ fields, onChange, formData = {} }) => {
  const [localFormData, setLocalFormData] = useState(formData);
  const [formErrors, setFormErrors] = useState({});

  // Sync local state when parent formData changes
  useEffect(() => {
    setLocalFormData(formData);
  }, [formData]);

  const handleInputChange = (event) => {
    const { name, value, checked, type } = event.target;
    const updatedValue = type === 'checkbox' ? checked : value;
    
    const updatedFormData = {
      ...localFormData,
      [name]: updatedValue
    };

    setLocalFormData(updatedFormData);
    onChange(name, updatedValue);
  };

  const validateField = (name, value) => {
    let error = "";
    if (name === "Name" && !/^[a-zA-Z-' ]+$/.test(value)) {
      error = "Invalid Name";
    } else if (name === "Email Address" && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      error = "Invalid Email";
    }
    setFormErrors(prev => ({ ...prev, [name]: error }));
  };

  return (
    <FormWrapper>
      {fields.map((field) => (
        <FieldContainer key={field.name}>
          {field.label && (
            <Label htmlFor={field.name}>
              {field.label} 
              {field.required && <RequiredStar>*</RequiredStar>}
            </Label>
          )}
          
          {field.type === 'textarea' ? (
            <TextArea
              id={field.name}
              name={field.name}
              value={localFormData[field.name] || ''}
              style={field.style}
              onChange={handleInputChange}
              placeholder={field.placeholder}
              onBlur={(e) => validateField(field.name, e.target.value)}
              disabled={field.disabled}
            />
          ) : field.type === 'radio' ? (
            <RadioGroup>
              {field.options.map((option) => (
                <RadioOption key={option.value}>
                  <input
                    type="radio"
                    id={`${field.name}-${option.value}`}
                    name={field.name}
                    value={option.value}
                    checked={localFormData[field.name] === option.value}
                    onChange={handleInputChange}
                  />
                  <label htmlFor={`${field.name}-${option.value}`}>
                    {option.label}
                  </label>
                </RadioOption>
              ))}
            </RadioGroup>
          ) : field.type === 'checkbox' ? (
            <CheckboxContainer>
              <input
                type="checkbox"
                id={field.name}
                name={field.name}
                checked={!!localFormData[field.name]}
                onChange={handleInputChange}
              />
              <label htmlFor={field.name}>{field.label}</label>
            </CheckboxContainer>
          ) : (
            <Input
              type={field.type}
              id={field.name}
              name={field.name}
              value={localFormData[field.name] || ''}
              style={field.style}
              onChange={handleInputChange}
              placeholder={field.placeholder}
              onBlur={(e) => validateField(field.name, e.target.value)}
              disabled={field.disabled}
              autoComplete={field.autoComplete || 'off'}
            />
          )}
          
          {formErrors[field.name] && (
            <ErrorMessage>{formErrors[field.name]}</ErrorMessage>
          )}
        </FieldContainer>
      ))}
    </FormWrapper>
  );
};

// Styled Components
const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

const Label = styled.label`
  font-size: 14px;
  color: #333;
  margin-bottom: 4px;
`;

const RequiredStar = styled.span`
  color: red;
  margin-left: 2px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ccc;
  border-radius: 50px;
  font-size: 14px;
  transition: border-color 0.3s;

  &:focus {
    outline: none;
    border-color: #0099fe;
  }

  &:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }

  ${media.small`
    padding: 8px 10px;
  `}
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ccc;
  border-radius: 50px;
  font-size: 14px;
  min-height: 100px;
  resize: vertical;
  font-family: inherit;

  &:focus {
    outline: none;
    border-color: #0099fe;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
`;

const RadioOption = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  input[type="radio"] {
    margin: 0;
  }

  label {
    font-size: 14px;
    cursor: pointer;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  input[type="checkbox"] {
    margin: 0;
  }

  label {
    font-size: 14px;
    cursor: pointer;
  }
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 2px;
`;

export default CustomForm;