import { ResponsiveAreaBump } from "@nivo/bump";
import { ResponsiveLine } from "@nivo/line";
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { media } from "../../utils/media";

const myColors = [
  "#0099FF",
  "#A962FF",
  "#26CDCD",
  "#9FC51E",
  "#FF832B",
  "#FCBB53",
  "#21AF4A",
  "#66C2FF",
  "#4673FF",
  "#F28D8C",
  "#7D7DE8",
  "#AFEEEE",
  "#DA70D6",
  "#98FB98",
  "#F58CBD",
  "#FFDD44",
  "#89CFF0",
  "#FF6F61",
  "#B1EDE8",
  "#C9A0DC",
  "#93DFB8",
  "#FFD700",
  "#F4A460",
  "#ADD8E6",
  "#FFB347",
  "#77DD77",
  "#DDA0DD",
  "#FFC0CB",
  "#FF6961",
  "#B39EB5",
  "#FFB6C1",
];
const blurNumber = (value, blurChar = "█") => {
  if (value === null || value === undefined) return value;
  const valueStr = String(value);
  const blurredValue = blurChar.repeat(valueStr.length);
  return blurredValue;
};
const TransformedNivoRibbonChart = ({ data }) => {
  const [transformedData, setTransformedData] = useState([]);
  const [transformedData2, setTransformedData2] = useState([]);
  const [isScale, setScale] = useState(false);
  const [isCurrency, setCurrency] = useState(false);
  const [visibility, setVisibility] = useState(true);
  const [title, setTitle] = useState(data?.Title || "");
  const [isUSDPresent, setIsUSDPresent] = useState(false);
  const [usdToINRRate, setUsdToINRRate] = useState(0);
  const legendPadding = 50;
  const fontSize = 9;
  const tickRotation = 0;
  const marginTop = 50;
  const marginRight = 50;
  const marginBottom = 70;
  const marginLeft = 60;
  const is_platform = false;
  const bigSize = 15;
  const smallSize = 5;
  const formatCode = "hi";
  const frequency = "M";
  const is_percent = false;
  const thousandScaling = false;
  const isBlurred = false;
  const legendDirection = "row";
  const topOption = [];
  const paddingRatio = 0.5;
  const showTotal = false;
  const is_usd = false;
  const isINR = data?.Title?.includes("INR");
  const isHashTitle = data?.Title?.includes("#");
  const isPercentage = data?.Title?.includes("%");
  const maxValue = Math.max(...(data?.Values?.flatMap(v => v.Data) || [0]));
  const isMnScale = isHashTitle && maxValue >= 1_000_000;
  const isCrScale = isINR && maxValue >= 10_000_000;
  const yAxisUnit = isMnScale ? "" : 
                 isCrScale ? "" : 
                 data?.Values?.[0]?.Unit || "";
  const visible = true;               
  const hideShowOthers = true;               
  const currency_unit = isCurrency
    ? isScale
      ? ` (${is_usd ? "USD" : "INR"} ${is_usd ? "Bn" : "Mn"})`
      : ` (${is_usd ? "USD" : "INR"})`
    : isScale
      ? ` (${is_usd ? "Mn" : "Cr"})`
      : ``;

   useEffect(() => {
      if (!data?.Title) return;
  
      const usdMatch = data.Title.match(/USD=([\d.]+)\s*INR/);
      if (usdMatch) {
        setIsUSDPresent(true);
        setUsdToINRRate(parseFloat(usdMatch[1]));     
        const maxValue = Math.max(...data.Values.flatMap((v) => v.Data));
        if (maxValue >= 1_000_000) {
          setTitle(data.Title.replace(/USD=[\d.]+ INR/, `USD=${usdMatch[1]} INR (USD Mn)`));
          setScale(true);
        } else {
          setTitle(data.Title);
        }
      } else {
        setTitle(data.Title);
      }
    }, [data]);
  
  useEffect(() => {
    if (!data) return;
    const areaBumpData = data?.Values.map(series => ({
      id: series.Legend,
      data: data?.Labels?.Data?.map((label, index) => ({
        x: label,
        y: series.Data[index]
      }))
    }));
    const lineData = data?.Values.map(series => ({
      id: series.Legend,
      data: data?.Labels?.Data?.map((label, index) => ({
        x: label,
        y: series.Data[index]
      }))
    }));

    setTransformedData(areaBumpData);
    setTransformedData2(lineData);
    setVisibility(visible);
  }, [data]);
  const CustomTooltip = ({ slice }) => {
    if (!slice || !slice.points) return null;
    const nonZeroPoints = slice.points.filter(point => {
      const value = point.data.y;
      return value !== null && value !== undefined && value !== 0;
    });  
    if (nonZeroPoints.length === 0) return null;
  
    const reversedData = [...nonZeroPoints].reverse();
    const topData = reversedData.filter(
      (obj) => (topOption.length === 0 || topOption.includes(obj.serieId)) &&
               obj.serieId !== "Overall"
    );
    
    const totalValue = topData.reduce((sum, dataPoint) => sum + dataPoint.data.y, 0);
    const totalValueList = reversedData.filter(obj => obj.serieId === "Overall");
  
    const otherData = reversedData.filter(
      (obj) => !topData.includes(obj) && obj.serieId !== "Overall"
    );
  
    const getSeriesColor = (serieId) => {
      const matchingItem = transformedData.find(d => d.year === serieId);
      return matchingItem?.color || 
             myColors[transformedData.findIndex(d => d.year === serieId) % myColors.length];
    };

    const getSeriesFormat = (seriesId) => {
      const series = data?.Values?.find(v => v.Legend === seriesId);
      return {
        prefix: series?.Prefix || "",
        postfix: series?.Postfix || ""
      };
    };
  
    const capitalizeFirst = str =>
      str ? str.charAt(0).toUpperCase() + str.slice(1) : "";

    const formatTooltipValue = (value, seriesId) => {
      const series = data?.Values?.find(v => v.Legend === seriesId);
      const prefix = capitalizeFirst(series?.Prefix || "");
      const postfix = capitalizeFirst(series?.Postfix || "");
      const hasCustomFormatting = prefix || postfix;

      let scaledValue, formattedValue, unit;
      if (isPercentage) {
        scaledValue = value;
        unit = "(%)";
      }
      else if (isMnScale) {
        scaledValue = value / 1_000_000;
        unit = "(Mn)";
      } else if (isCrScale) {
        scaledValue = value / 10_000_000;
        unit = "(INR Cr)";
      } else {
        scaledValue = value;
        unit = series?.Unit ? capitalizeFirst(series.Unit) : "";
      }

      formattedValue = scaledValue.toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      if (isUSDPresent && isMnScale && !hasCustomFormatting) {
        formattedValue = `$ ${formattedValue}`;
      } else if (isCrScale && !hasCustomFormatting) {
        formattedValue = `₹ ${formattedValue}`;
      }

      return {
        formattedValue: hasCustomFormatting
          ? `${prefix} ${formattedValue} ${postfix}`
          : `${formattedValue}`,
        unit: hasCustomFormatting ? '' : unit
      };
    };
    return (
      <TootTipWrapper fontSize={fontSize}>
        {showTotal && totalValueList.length > 0 && (
          <TootTipRow>
            <TootTipLabel bold={true}>
              <SquareBox size={bigSize} color={"#cccccc"} bold={true} />
              Overall
            </TootTipLabel>
            <TootTipValue bold={true}>
              {isBlurred
                ? blurNumber(formatTooltipValue(totalValueList[0].data.y, totalValueList[0].serieId).formattedValue)
                : formatTooltipValue(totalValueList[0].data.y, totalValueList[0].serieId).formattedValue}
              <TootTipValueUnit fontSize={Number(fontSize * 0.8)} bold={true}>
                {formatTooltipValue(totalValueList[0].data.y, totalValueList[0].serieId).unit}
              </TootTipValueUnit>
            </TootTipValue>
          </TootTipRow>
        )}
        {topData.map((point, idx) => {
          const formatted = formatTooltipValue(point.data.y, point.serieId);          
          return (
            <TootTipRow key={idx}>
              <TootTipLabel>
                <SquareBox size={bigSize} color={point.serieColor} />
                {point.serieId}
              </TootTipLabel>
              <TootTipValue>
                {isBlurred
                  ? blurNumber(formatted.formattedValue)
                  : formatted.formattedValue}
                <TootTipValueUnit fontSize={Number(fontSize * 0.8)}>
                  {formatted.unit}
                </TootTipValueUnit>
              </TootTipValue>
            </TootTipRow>
          );
        })}
        { hideShowOthers &&  otherData.map((point, idx) => {
           const formatted = formatTooltipValue(point.data.y, point.serieId);
          return (
            <TootTipOtherRow key={idx} margin={bigSize}>
              <TootTipLabel>
                <SquareBox size={bigSize} color={point.color} />
                - {point.serieId}
              </TootTipLabel>
              <TootTipValue>
                {isBlurred
                  ? blurNumber(formatted.formattedValue)
                  : formatted.formattedValue}
                <TootTipValueUnit fontSize={Number(fontSize * 0.8)}>
                  {formatted.unit}
                </TootTipValueUnit>
              </TootTipValue>
            </TootTipOtherRow>
          );
        })}
      </TootTipWrapper>
    );
  };
  const LabelLayer = ({ series }) => {
    let maxY = -10000;
    let decimalCount = 2;
    let cordinates = {};
    series.forEach(({ id, data, color, points }) => {
      data.data.forEach((point, idx) => {
        let newArry = [];
        if (cordinates[points[idx].x]) {
          newArry = cordinates[points[idx].x];
        }
        if (point.y > maxY) {
          maxY = point.y;
        }
        newArry.push(points[idx].y);
        cordinates[points[idx].x] = newArry.sort((a, b) => b - a);
      });
    });

    if (maxY > 100) {
      decimalCount = 1;
    }
    if (maxY > 1000) {
      decimalCount = 0;
    }

    let validCordinated = {};
    Object.keys(cordinates).forEach((x) => {
      let yCords = [];
      cordinates[x].forEach((y) => {
        if (yCords.length === 0 || yCords[yCords.length - 1] - y >= fontSize) {
          yCords.push(y);
        }
        if (yCords.length) {
        }
      });
      validCordinated[x] = yCords;
    });

    return (
      <g>
        {series.map(({ id, data, color, points }) => {
          return data.data.map((point, idx) => {
            let legendVisible = false;
            if (
              validCordinated[points[idx].x]?.filter(
                (yCord) => yCord === points[idx].y
              ).length &&
              Number(point.y.toFixed(decimalCount))
            ) {
              validCordinated[points[idx].x] = validCordinated[
                points[idx].x
              ].filter((yCord) => yCord !== points[idx].y);
              legendVisible = true;
            }
            const scaleThousand = thousandScaling && point.y > 1000;
            const pointDecimal = scaleThousand
              ? point.y > 10000
                ? 0
                : 1
              : decimalCount;
              const formattedValue = legendVisible
              ? `${Number(
                  (scaleThousand ? point.y / 1000 : point.y).toFixed(
                    pointDecimal
                  )
                ).toLocaleString(formatCode, {
                  minimumFractionDigits: pointDecimal,
                  maximumFractionDigits: pointDecimal,
                })}${scaleThousand ? "K" : ""}${is_percent ? "%" : ""}`
              : null
              const displayValue = legendVisible && isBlurred 
            ? blurNumber(formattedValue)
            : formattedValue;
            return (
              <text
                key={`${id}-${point.x}`}
                x={points[idx].x}
                y={points[idx].y}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                  fill: "#262e40",
                  // fill: "#262e40",
                  fontSize: fontSize,
                  fontWeight: "normal",
                  // textShadow: "1px 1px 2px rgba(255,255,255,0.7)",
                  // textShadow:
                  //   "1px 1px 2px rgba(255,255,255,0.7), -1px -1px 2px rgba(255,255,255,0.7)",
                  filter: isBlurred ? "blur(10px)" : "none",
                }}
              >
                {displayValue}
              </text>
            );
          });
        })}
      </g>
    );
  };

  // const CustomLegendLayer = ({ series }) => {
  //   const showlegend = true;
  //   const filteredSeries = series.filter(
  //     (obj) => topOption.length === 0 || topOption.includes(obj.id)
  //   );
  
  //   // Calculate rows needed
  //   const itemsPerRow = filteredSeries.length > 8
  //     ? Math.ceil(filteredSeries.length / 3)
  //     : filteredSeries.length;
    
  //   const rowSpacing = 20;
  //   const colSpacing = 100;    
  //   const rowCount = Math.ceil(filteredSeries.length / itemsPerRow);
  //   const legendHeight = rowCount * rowSpacing;
    
  //   const legendTopPadding = -3;
  //   const truncateText = (text, maxLength = 20) => {
  //     if (text.length <= maxLength) return text;
  //     return text.substring(0, maxLength - 3) + "...";
  //   };
  //   return (
  //     <g transform={`translate(${legendPadding - marginLeft},${-marginTop + legendTopPadding})`}>
  //       {showlegend &&
  //         filteredSeries.map((item, index) => {
  //           const row = Math.floor(index / itemsPerRow);
  //           const col = index % itemsPerRow;
  //           const x = col * colSpacing;
  //           const y = row * rowSpacing;
  //           const displayText = truncateText(item.id);
  //           return (
  //             <g key={item.id} transform={`translate(${x}, ${y})`}>
  //               <circle cx={10} cy={10} r={bigSize / 2} fill={item.color} />
  //               <text
  //                 x={20}
  //                 y={10}
  //                 textAnchor="start"
  //                 alignmentBaseline="middle"
  //                 fill="#000000"
  //                 fontSize={fontSize}
  //               >
  //               {displayText}
  //               </text>
  //             </g>
  //           );
  //         })}
  //     </g>
  //   );
  // };
  const CustomLegendLayer = ({ series }) => {
    const isRow = legendDirection === "row";
    let xPosition = legendPadding - marginLeft;
    let yPosition = -marginTop + bigSize / 2;
    let showlegend = true
    return (
      <g>
        {showlegend &&
          series
            .filter(
              (obj) => topOption.length === 0 || topOption.includes(obj.id)
            )
            .map((item, index) => {
              const xCurrentPosition = xPosition;
              xPosition = (item.id.length * fontSize) / 2 + xPosition + 30;
              const yCurrentPosition = yPosition;
              yPosition = yPosition + 12;
              return (
                <g
                  key={item.id}
                  transform={
                    isRow
                      ? `translate(${xCurrentPosition},${
                          -marginTop + bigSize / 2
                        })`
                      : `translate(${
                          legendPadding - marginLeft
                        },${yCurrentPosition})`
                  }
                >
                  <circle cx={10} cy={0} r={bigSize / 2} fill={item.color} />
                  <text
                    x={20}
                    y={0}
                    textAnchor="start"
                    alignmentBaseline="middle"
                    fill="#000000"
                    fontSize={fontSize}
                  >
                    {item.id}
                  </text>
                </g>
              );
            })}
      </g>
    );
  };
  if (!transformedData.length) {
    return <div>Loading chart...</div>;
  }

  return (
    <Wrapper visible={visibility}> 
      <PositionWrapper>
        <GraphWrapper>
          <ResponsiveAreaBump
            data={transformedData}
            margin={{
              top: marginTop,
              right: marginRight,
              bottom: marginBottom + 10, // Increased bottom margin
              left: marginLeft + 10, // Increased left margin
            }}
            xOuterPadding={0.6}
            theme={{
              axis: {
                domain: {
                  line: {
                    stroke: '#777',
                    strokeWidth: 1.5
                  }
                },
                ticks: {
                  line: {
                    stroke: '#777',
                    strokeWidth: 1.5 // Made tick lines thicker
                  },
                  text: {
                    fontSize: fontSize,
                    fill: "#333333",
                    fontWeight: 500 // Added font weight
                  }
                },
                legend: {
                  text: {
                    fontSize: fontSize + 1,
                    fill: "#333333",
                    fontWeight: 600 // Added font weight to legend text
                  }
                }
              },
              text: {
                fontSize: fontSize,
                fill: "#333333"
              },
              tooltip: {
                container: {
                  background: "#ffffff",
                  fontSize: fontSize
                }
              }
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              tickSize: 7, 
              tickPadding: 7, 
              tickRotation: tickRotation,
              legendOffset: 40,
              truncateTickAt: 0,
            }}
            axisLeft={{
              orient: 'left', 
              tickSize: 7, 
              tickPadding: 7,
              tickRotation: 0,
              legendOffset: 40,
              format: value => `${value}%`,
              truncateTickAt: 0,
            }}
            enableGridX={true}
            enableGridY={false}
            enableSlices="x"
            align="end"
            xPadding={0.9}
            // colors={{ scheme: "nivo" }}
            colors={
              is_platform
                ? (series) => {
                    if (!myColors || !myColors[series.id]) {
                      console.warn(`Color mapping missing for series: ${series.id}`);
                      return "#FAEBD7"; // Default color if mapping is missing
                    }
                    return myColors[series.id];
                  }
                : myColors
            }
            blendMode="multiply"
            fillOpacity={0.8}
            inactiveFillOpacity={0.4}
            borderWidth={0}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.4]],
            }}
            startLabelPadding={11}
            endLabel={false}
            isInteractive={false}
            layers={[
              // "grid",
              "axes",
              "labels",
              "areas",
              "crosshair",
              "mesh",
              "legends",
              LabelLayer,
            ]}
            motionConfig="stiff"
          />
        </GraphWrapper>
        
        <GraphWrapper2>
          <ResponsiveLine
           data={transformedData2}
           colors={
             is_platform
               ? (series) => {
                   if (!myColors || !myColors[series.id]) {
                     console.warn(`Color mapping missing for series: ${series.id}`);
                     return "#FAEBD7"; // Default color if mapping is missing
                   }
                   return myColors[series.id];
                 }
               : myColors
           }
           margin={{
             top: marginTop,
             right: marginRight,
             bottom: marginBottom,
             left: marginLeft,
           }}
           theme={{
             background: "#ffffff00",
             text: {
               fontSize: fontSize,
               fill: "#333333",
               outlineWidth: 0,
               outlineColor: "transparent",
             },
             tooltip: {
               wrapper: {},
               container: {
                 background: "#ffffff",
                 color: "#333333",
                 fontSize: fontSize,
               },
               basic: {},
               chip: { height: bigSize, width: bigSize },
               table: {},
               tableCell: {},
               tableCellValue: {},
             },
           }}
           xScale={{ type: "point" }}
           yScale={{
             type: "linear",
             min: "auto",
             max: "auto",
             stacked: false,
             reverse: false,
           }}
           // yFormat=" >-.2f"
           
           yFormat={(value) =>{
             const formatted = `${Number(value.toFixed(2)).toLocaleString(formatCode, {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2,
             })}`;
             return isBlurred ? blurNumber(formatted) : formatted;
           }}
           axisTop={null}
           axisRight={null}
           // axisBottom={{
           //   tickSize: 5,
           //   tickPadding: 5,
           //   tickRotation: tickRotation,
           //   legend: null,
           //   legendOffset: 36,
           //   legendPosition: "middle",
           //   truncateTickAt: 0,
           // }}
           axisBottom={null} //this change
           axisLeft={false}
           enableGridX={false}
           enableGridY={false}
           lineWidth={0} //this change
           enablePoints={false} //this change
           pointSize={smallSize}
           pointColor={{ from: "color", modifiers: [] }}
           pointBorderWidth={2}
           pointBorderColor={{ from: "color", modifiers: [] }}
           enablePointLabel={true}
           pointLabel="data.yFormatted"
           pointLabelYOffset={-12}
           areaOpacity={0}
           enableSlices="x"
           enableCrosshair={true}
           enableTouchCrosshair={true}
           useMesh={true}
           // legends={
           //   showlegend
           //     ? [
           //         {
           //           anchor: "top-left",
           //           itemDirection: "left-to-right",
           //           direction: legendDirection,
           //           justify: false,
           //           translateX: legendX,
           //           translateY: -marginTop,
           //           // itemWidth: 80,
           //           itemWidth: (d) => {console.log("dddddd-",d); return 80 },
           //           itemHeight: bigSize,
           //           itemsSpacing: 5,
           //           symbolSize: bigSize,
           //           symbolShape: "circle",
           //           itemTextColor: "#777",
           //           effects: [
           //             {
           //               on: "hover",
           //               style: {
           //                 itemBackground: "rgba(0, 0, 0, .03)",
           //                 itemOpacity: 1,
           //               },
           //             },
           //           ],
           //           titleAlign: "start",
           //           titleOffset: 4,
           //         },
           //       ]
           //     : []
           // }
           legends={[]}
           motionConfig="default"
           layers={[
             "grid",
             "markers",
             "areas",
             "lines",
             "slices",
             "points",
             "axes",
             "crosshair",
             CustomLegendLayer,
           ]}
           sliceTooltip={CustomTooltip}
          />
        </GraphWrapper2>
      </PositionWrapper>
    </Wrapper>
  );
};

// Styled components
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  pointer-events: ${(props) => (props.visible ? "auto" : "none")};
`;


const PositionWrapper = styled.div`
  width: 100%;
  min-width: 600px; 
  height: 100%;
  position: relative;
`;

const GraphWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  filter: ${props => props.isBlurred ? 'blur(4px)' : 'none'};
  user-select: ${props => props.isBlurred ? 'none' : 'auto'};
`;

const GraphWrapper2 = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  filter: ${props => props.isBlurred ? 'blur(4px)' : 'none'};
  user-select: ${props => props.isBlurred ? 'none' : 'auto'};
`;
const TootTipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 8px;
  border: 0.5px solid #cccccc;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  font-size: ${(props) => props.fontSize}px;
`;

const TootTipRow = styled.div`
  width: 100%;
  display: flex;
  padding: 2.5px;
  flex-direction: row;
  justify-content: space-between;
`;

const TootTipOtherRow = styled.div`
  width: 100%;
  display: flex;
  padding: 2.5px;
  flex-direction: row;
  justify-content: space-between;
  padding-left: ${(props) => 12.5 + props.margin}px;
`;

const TootTipLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
  font-weight: ${(props) => (props.bold ? "500" : "400")};
`;

const SquareBox = styled.div`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  background-color: ${(props) => props.color};
  margin-right: 10px;
  border: ${(props) =>
    props.bold ? "1.5px solid #262e40" : "0px solid #262e40"};
`;

const TootTipValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: ${(props) => (props.bold ? "500" : "400")};
`;

const TootTipValueUnit = styled.div`
  font-size: ${(props) => props.fontSize}px;
  margin-left: 3px;
  color: #a0a6a9;
  font-weight: 350;
  font-weight: ${(props) => (props.bold ? "500" : "350")};
`;

export default TransformedNivoRibbonChart;