import styled from "styled-components";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { media } from "../../utils/media";
const TermsAndConditions = () => {
  return (
    <PageWrapper>
      <Header />
      <DataSection>
        <ContentContainer>
          <h1>Terms of Use</h1>
          <div>
            <div>Effective Date: March 20,2025</div>
          </div>
          <div>
            The web platform{" "}
            <a href="https://benchmarks.digital/" target="blank">
              https://benchmarks.digital/
            </a>{" "}
            <b>(“Platform”)</b> is owned and operated by Redseer Strategy
            Consultants Private Limited, having its registered office at
            Prestige Obelisk, no 3, 5th Floor Kasturba Road, Mahatma Gandhi
            Road, Bangalore, India - 560001{" "}
            <b>(“Company”, “we” or “us” or “our”)</b>.
          </div>
          <div>
            Any reference of “you”, “your”, or “user” in this terms of use would
            mean the person who accesses and/or uses the Platform, in any manner
            whatsoever.
          </div>
          <div>
            The Company provides certain market research and intelligence
            insights and related data to its customers in India through the
            Platform <b>(“Services”)</b>. These terms of use govern your access
            to and use of our Platform, including all services, data, and
            content made available through the Platform <b>(“Terms of Use”)</b>.
          </div>
          <div>
            Please read these Terms of Use, along with the privacy policy
            available at{" "}
            <a
              href={`https://benchmarks.digital/privacy-policy`}
              target="blank"
              style={{ textDecoration: "none" }}
            >
              <b>(“Privacy Policy”)</b>
            </a>{" "}
            and all other rules and policies made available or published on the
            Platform as they shall govern your use of the Platform, and the
            Services provided thereunder.
          </div>
          <div>
            By using or visiting the Platform, you signify your agreement to
            these Terms of Use and the Privacy Policy. By downloading,
            registering, signing up for the Platform or otherwise accessing,
            receiving, or using the Platform, you acknowledge that you have
            read, understood, and consented to be governed and bound by these
            Terms of Use and the Privacy Policy. If you do not accept or agree
            to any part of these Terms of Use or the Privacy Policy, please do
            not use the Platform.
          </div>
          <div>
            These Terms of Use are an electronic record as per the Information
            Technology Act, 2000 (as amended/re-enacted) and rules thereunder{" "}
            <b>(“IT Act")</b> This electronic record is generated by a computer
            system and does not require any physical or digital signature.
          </div>
          <h2>1. SERVICES AND ELIGIBILITY</h2>
          <p>
            Only persons who can form legally binding contracts under the Indian
            Contract Act, 1872 may access the Platform and avail our Services.
            Persons who are ‘incompetent to contract’ within the meaning of the
            Indian Contract Act, 1872, including, without limitation,
            un-discharged insolvents, are not eligible to avail our Services. If
            you are a minor i.e., under the age of 18 (eighteen) years, you
            cannot register and/or avail our Services. We reserve the right to
            refuse to provide you with access to the Services if it is brought
            to our notice or if it is discovered that you are incompetent to
            contract. You represent and warrant to us that you are of legal age
            to form a binding contract and are not a person barred from availing
            the Services under applicable laws.
          </p>
          <h2>2. ACCOUNT REGISTRATION AND RESPONSIBILITIES</h2>
          <div>
            (i) <b>Account Creation</b>
          </div>
          <p>
            <b>a. </b>To access certain features of the Platform, you must create
            an account by providing accurate and complete information. You may
            log into the Platform using either your (i) log-in ID or (ii) log-in
            ID and one time password. You hereby represent and warrant that all
            the information provided by you for the purpose of creating an
            account is true, accurate and complete. Use of another user’s
            account information for availing the Services is expressly
            prohibited. If it is found that the information so supplied on the
            Platform is inaccurate, incomplete, untrue and not correct, we have
            the right to suspend or terminate the user’s account and
            restrict/refuse the use of the Platform by such user in future.
            Further, you agree not to provide any false personal information to
            us (including false/fraudulent login details) or create any account
            for anyone other than yourself without such person's explicit
            permission or use the login details of another person with the
            intent to impersonate that person.
          </p>
          <div>
            (ii)<b> Account Types</b>
          </div>
          <p>
            <b>a. </b> <strong>Freemium Accounts:</strong> Users can sign up for
            limited access to the Platform’s datasets via a "request invite"
            feature.
          </p>
          <p>
            <b>b. </b>
            <strong>Paid Accounts:</strong> Users gain access to specific
            players, sectors, or datasets as per their subscription plan.
          </p>
          <div>
            (iii) <b>Account Security</b>
          </div>
          <p>
            You are responsible for all activities under your account.Please
            Notify us immediately at{" "}
            <a href="mailto:compliance@redseerconsulting.com">
              compliance@redseerconsulting.com
            </a>{" "}
            if you suspect unauthorized use of your account.
          </p>

          <h2>3. USE OF THE PLATFORM</h2>
          <div>
            (i) <b>Permitted Use</b>{" "}
          </div>
          <p>You may use the Platform solely for:</p>
          <p>
            <b>a. </b> Accessing market intelligence insights and data provided
            through your account type.
          </p>
          <p>
            {" "}
            <b>b. </b>Internal business purposes or personal research, as
            permitted under your subscription.
          </p>
          <div>
            (ii)<b> Prohibited Activities</b>
          </div>
          <p>You agree not to:</p>
          <p>
            <b>a. </b>Share, sell, or redistribute the data or insights obtained
            through the Platform without prior written consent of the Company;
          </p>
          <p>
            {" "}
            <b>b. </b>Use the Platform to engage in activities that are unlawful,
            infringe on intellectual property rights, or harm the Platform’s
            integrity.
          </p>
          <p>
            {" "}
            <b>c. </b>Access or attempt to access areas of the Platform that you
            are not authorized to use.
          </p>
          <p>
            <b>d. </b>Reverse engineer, scrape, or use automated tools to extract
            data from the Platform.
          </p>
          <h2>4. SUBSCRIPTIONS AND PAYMENTS</h2>
          <div>
            (i) <b>Subscription Plans</b>{" "}
          </div>
          <p>
            Paid users must select a subscription plan, which outlines access
            levels and pricing. Fees are billed in advance and are
            non-refundable, except as required by applicable law or explicitly
            stated.
          </p>
          <div>
            (ii) <b>Free Trials and Promotions</b>
          </div>
          <p>
            We may offer free trials or promotional access. Upon expiration of a
            trial period, continued access may require payment by you.
          </p>
          <div>
            (iii) <b>Payment Terms</b>
          </div>
          <p>
            Payments are processed via secure third-party gateways. You agree to
            provide accurate billing information and authorize us to charge your
            selected payment method.
          </p>
          <div>
            (iv) <b>Cancellations</b>
          </div>
          <p>
            You may cancel your subscription at any time, subject to any other
            terms that you’ve contractually agreed with us, which shall prevail.
            Cancellation will apply to the next billing cycle, and you will
            retain access to the Platform until the end of the current billing
            period, unless you’re found to be in breach of section 3 (ii), in
            which case your access will be revoked immediately.{" "}
          </p>

          <h2>5. INTELLECTUAL PROPERTY</h2>
          <div>
            (i)<b> Ownership</b>
          </div>
          <p>
            <b>a. </b>You agree and acknowledge that we are, and we will remain
            the owner of the Platform and the Services thereunder, at all times.
            You acknowledge that copyright in works contained on the Platform
            and the Services, including but not limited to all the features,
            functionality, software, design, text, sound recordings and images,
            are our exclusive property or licensed by us, except as otherwise
            expressly stated. You may access the Platform as a bona fide visitor
            or only for your use of the Services offered.
          </p>
          <p>
            <b>b. </b>All trademarks, service marks, trade names, trade dress,
            and other forms of intellectual property are proprietary to us. No
            information, code, algorithms, content, or material from the
            Platform or the Services may be copied, reproduced, republished,
            uploaded, posted, transmitted or distributed in any way without our
            express written permission.
          </p>
          <p>
            <b>c. </b>All third-party names, logos, product and service names,
            designs, and slogans (collectively,{" "}
            <b>“Third-Party Trademark(s)”</b>) contained in the Service are the
            property of their respective owners. Third-Party Trademarks are used
            by us to refer to the owners of the Third-Party Trademark(s) in
            question and use by us is not intended to (and does not) constitute
            or imply any kind of relationship between us and the owners of the
            Third-Party Trademark(s) in question, including any affiliation,
            sponsorship, endorsement or approval of products and/or services,
            unless otherwise expressly indicated.
          </p>
          <div>
            (ii)<b> Limited License</b>
          </div>
          <p>
            <b>a. </b>We grant you a limited, non-exclusive, non-transferable,
            non-sublicensable and revocable license to access and use the
            Platform in accordance with these Terms of Use.
          </p>
          <div>
            (iii)<b> Restrictions</b>
          </div>
          <p>
            <b>a. </b>You may not copy, modify, reproduce, distribute, or create
            derivative works based on any content from the Platform without our
            prior written consent.
          </p>

          <h2>6. TERMINATION</h2>
          <p>
            We may terminate your access to all or any part of the Platform or
            the Services at any time, with or without cause, with or without
            notice, effective immediately, including but not limited to, as a
            result of your breach of these Terms of Use or the Privacy Policy.
            Any suspected illegal, fraudulent, or abusive activity will also be
            grounds for terminating your access to the Platform and/or Services.{" "}
          </p>
          <p>
            We reserve the right to, at our sole discretion, (a) cease operating
            the Platform or any of the Services at any time without notice,
            and/or (b) terminate these Terms of Use.
          </p>
          <p>
            Upon termination, your rights under these Terms of Use will
            immediately cease. Sections related to intellectual property,
            indemnification, disclaimers, and limitations of liability will
            survive termination.
          </p>
          <h2>7. DISCLAIMER OF WARRANTIES</h2>
          <p>
            The Platform and the Services are provided on an “as is” and “as
            available” basis, without warranties of any kind, either express or
            implied, including, but not limited to, the implied warranties of
            title, non-infringement, merchantability and fitness for a
            particular purpose, and any warranties implied by any course of
            performance or usage of trade, all of which are expressly
            disclaimed, save to the extent required by law. We do not guarantee
            the accuracy, completeness, merchantability or reliability of the
            data or insights provided through the Platform. Further, we do not
            commit to ensuring that the Platform remains available at all times
            or that the information provided on the Platform is up to date.{" "}
          </p>
          <p>
            Additionally, by using the Platform or any of the Services, you
            acknowledge and agree that internet transmissions are never
            completely private or secure. You understand that any message or
            information you send using the Platform or any of the Services may
            be read or intercepted by others, even if there is a special notice
            that a particular transmission is encrypted.
          </p>
          <p>Your use of the Platform is at your own risk.</p>
          <h2>8. INDEMNIFICATION AND LIMITATION OF LIABILITY</h2>
          <p>
            You agree to indemnify and hold harmless the Company, its officers,
            directors, and employees from and against any losses, damages,
            costs, expenses and claims arising out of (i) your use of the
            Platform or the Services; (ii) any breach of these Terms of Use or
            Privacy Policy by you; (iii) any infringement of intellectual
            property or other rights of the Company or any third party; (iv) any
            misrepresentation, negligence, misconduct, or fraud by you with
            regard to your information or use of the Platform; or (v) your
            breach of any applicable laws.{" "}
          </p>
          <p>
            To the fullest extent permitted by law, the Company and its
            affiliates will not be liable for any direct, indirect, special,
            incidental, punitive, exemplary, economic, or consequential damages
            arising from the use of the Platform and/or Services. This includes,
            but is not limited to, damages based on warranty, contract, tort
            (including negligence), or any other legal theory, even if the
            Company has been advised of the possibility of such damages. You
            expressly understand and agree that, under no circumstances, shall
            the Company be liable to you or any other person or entity for any
            damages, including but not limited to loss of profits, goodwill,
            use, data, or other intangible losses, resulting from circumstances,
            including with respect to:{" "}
          </p>
          <p>
            <b>a. </b>the use or the inability to use the Platform and/or the
            Services;
          </p>
          <p>
            <b>b. </b>your reliance on the statements or claims made by us in the
            course of rendering our Services; or
          </p>
          <p>
            <b>c. </b>any other matter relating to the Platform and/or Services.
          </p>
          <p>
            In any event, the total liability of the Company and its affiliates
            to you, for any claims arising out of or relating to the use of the
            Platform and/or Services shall not exceed the amount you paid, if
            any, for the specific Service provided. This limitation applies
            regardless of the form of action, whether in contract, tort, or any
            other legal theory.
          </p>
          <h2>9. THIRD-PARTY LINKS</h2>
          <p>
            The Platform includes links to third-party websites and/or
            applications. You acknowledge that when you access a third-party
            link that leaves the Platform:
          </p>
          <p>
            (i) the website or application you access is not controlled by the
            Company and different terms of use and privacy policies may apply;
          </p>
          <p>
            (ii)the inclusion of a link does not imply any endorsement by the
            Company of the third-party website and/or application, the website’s
            and/or application’s provider, or the information on the third-party
            website and/or application; and
          </p>
          <p>
            (iii)if you submit any information or details on any of those
            websites and/or applications, such information is governed by the
            terms of use and privacy policies of such third-party websites
            and/or applications and the Company disclaims all responsibility or
            liability with respect to these terms of use, policies or the
            websites and/or applications.
          </p>
          <p>
            You are encouraged to carefully read the terms of use and privacy
            policy, of any third-party website and/or application that you
            visit. The Company reserves the right to disable the Third-Party
            Links from the Platform, although the Company is under no obligation
            to do so. Company assumes no responsibility for the content,
            accuracy, terms and conditions, privacy policies, or practices of or
            opinions expressed in any Third-Party Link. In addition, by
            accessing the Third-Party Link, you release and hold us harmless
            from any and all liability arising from your access or use of any
            Third-Party Link.
          </p>
          <h2>10. SEVERABILITY:</h2>
          <p>
            If any provision of these Terms of Use is found to be unenforceable
            or invalid, that provision will be limited or eliminated to the
            minimum extent necessary so that these Terms of Use will otherwise
            remain in full force and effect and enforceable. The failure of
            either party to exercise, in any respect any right provided for
            herein shall not be deemed a waiver of any further rights hereunder.
            Waiver of compliance in any particular instance does not mean that
            we will waive compliance in the future. In order for any waiver of
            compliance with these Terms of Use to be binding, we must provide
            you with written notice of such waiver through one of our authorized
            representatives.
          </p>
          <h2>11. GOVERNING LAW</h2>
          <p>
            These Terms of Use shall be governed by and construed in accordance
            with the laws of India, without regard to conflict of law
            principles. You consent to the exclusive jurisdiction of the courts
            located in Bangalore, India. Use of the Service is not authorized in
            any jurisdiction that does not give effect to all provisions of
            these Terms of Use, including without limitation, this section.
          </p>
          <h2>12. CHANGES TO THESE TERMS</h2>
          <p>
            We reserve the right, at our sole discretion, to modify or replace
            any of these Terms of Use, or change, suspend, or discontinue the
            Services (including without limitation, the availability of any
            feature, database, or content) or its usage at any time by posting a
            notice or by sending you notice through our Service or via
            email/contact details provided as Login Details. We may also impose
            limits on certain features and services or restrict your access to
            parts or all of the Services without notice or liability. It is your
            responsibility to check these Terms of Use periodically for changes.
            Your continued use of the Services following the posting of any
            changes to these Terms of Use shall constitute an unconditional
            acceptance of those changes.{" "}
          </p>
          <h2>13. MISCELLANEOUS</h2>
          <p>
            (i)Unless otherwise specified in these Terms of Use, all notices
            hereunder will be in writing and will be deemed to have been duly
            given when received or when receipt is electronically confirmed, if
            transmitted by e-mail.
          </p>
          <p>
            (ii)In respect of these Terms of Use and your use of these Services,
            nothing in these Terms of Use shall be deemed to grant any rights or
            benefits to any person, other than us and you, or entitle any third
            party to enforce any provision hereof, and it is agreed that we do
            not intend that any provision of these Terms of Use should be
            enforceable by a third party as per any applicable law.
          </p>
          <p>
            (iii)Failure by Company to enforce any provision(s) of these Terms
            of Use will not be construed as a waiver of any provision or right.
          </p>
          <h2>14. CONTACT US</h2>
          <p>
            In the event that you wish to raise a query or complaint with us,
            please contact our Grievance Officer (contact details set out below)
            who shall acknowledge your complaint within 24 (twenty-four) hours
            from the time of receipt of such complaint.
          </p>
          <p>
            {/* <b>Name:</b> Kailash Iyer<br/> */}
            <b>Contact Number:</b> 9686111429
            <br />
            <b>Email Id:</b>{" "}
            <a href="mailto:compliance@redseerconsulting.com">
              compliance@redseerconsulting.com
            </a>
          </p>
        </ContentContainer>
      </DataSection>
      <Footer />
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #eef9ff;
`;

const DataSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 3.5vw 0px;
  margin-bottom: 10px;
`;

const ContentContainer = styled.div`
  background: #fff;
  padding: 40px;
  border-radius: 8px;
  h1 {
    font-size: 22px;
    font-weight: 600;
    ${media.small`
      font-size: 20px;  
    `}
  }

  h2 {
    font-size: 16px;
    color: #333;
    font-weight: 600;
    ${media.small`
      font-size: 14px;  
    `}
  }

  section {
    margin-bottom: 30px;
  }
  div {
    font-size: 14px;
    line-height: 1.8;
    color: #444;
    margin-bottom: 7px;
  }
  p {
    font-size: 14px;
    line-height: 1.8;
    color: #444;
    margin-bottom: 7px;
    margin-right: 10px;
    margin-left: 33px;
    &:last-child {
      margin-bottom: 0;
    }
    ${media.small`
      font-size: 12px;  
    `}
  }
`;

export default TermsAndConditions;
