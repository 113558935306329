import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import CustomForm from '../components/CustomForm/CustomForm';
import MyContext from '../utils/contexts/MyContext';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { onNewsletterClickCT, onUserLoginCT, onOTPLoginClickSuccessCT } from "../utils/clevertap";
import { media } from '../utils/media';

const Otp3 = () => {
    const [formData, setFormData] = useState({});
    const [wrongOTP, setWrongOTP] = useState(false);
    const { treeData, setTreeData } = useContext(MyContext);
    const [signIn, setSignIn] = useState(false);
    const [displayEmail, setDisplayEmail] = useState('');
    
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const navigate = useNavigate();

    // Get all parameters from URL or localStorage
    const url_source = searchParams.get('source');
    const url_email = searchParams.get('email');
    const url_newsletter_name = searchParams.get('newsletter_name');
    const url_newsletter_month = searchParams.get('newsletter_month');
    const url_newsletter_user = searchParams.get('newsletter_user');
    const url_newsletter_email = searchParams.get('newsletter_email');

    // Set state from URL or localStorage with proper fallbacks
    const source = url_source || window.localStorage.getItem('source') || "";
    const email = url_email || window.localStorage.getItem('email') || "";
    const newsletter_name = url_newsletter_name || window.localStorage.getItem('newsletter_name') || "";
    const newsletter_month = url_newsletter_month || window.localStorage.getItem('newsletter_month') || "";
    const newsletter_user = url_newsletter_user || window.localStorage.getItem('newsletter_user') || "";
    const newsletter_email = url_newsletter_email || window.localStorage.getItem('newsletter_email') || "";

    useEffect(() => {
        // Set the display email and store it
        if (email) {
            setDisplayEmail(email);
            window.localStorage.setItem('email', email);
        }

        // Newsletter tracking
        const timeoutId = setTimeout(() => {
            if (newsletter_name && newsletter_month && newsletter_user && newsletter_email) {
                onNewsletterClickCT(
                    newsletter_name,
                    newsletter_month,
                    "clicked",
                    newsletter_email,
                    "Otp Page"
                );
            }
        }, 1000);

        // Cleanup function
        return () => {
            window.localStorage.removeItem("newsletter_name");
            window.localStorage.removeItem("newsletter_month");
            window.localStorage.removeItem("newsletter_user");
            window.localStorage.removeItem("newsletter_email");
            clearTimeout(timeoutId);
        };
    }, [email, newsletter_name, newsletter_month, newsletter_user, newsletter_email]);

    // Handle source-based email submission
    useEffect(() => {
        if (source) {
            const handleSubmitEmail = () => {
                fetch(`${process.env.REACT_APP_API_ENDPOINT}/authorise/login/?email=${email}`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                })
                .then(data => data.json())
                .then(data => {
                    if (data['otp_access'] === true) {
                        window.localStorage.setItem("pseudo_email", data['pseudo_email']);
                        window.localStorage.setItem("clientID", data['client_id']);
                        window.localStorage.setItem('unregistered', data['unregistered']);
                        window.localStorage.setItem('user_name', data['user_name']);
                        
                        if (data["investor_id"]) {
                            window.localStorage.setItem("investor_id", data["investor_id"]);
                        }
                        
                        window.localStorage.setItem('gender_male', data['gender_male'] === true ? "true" : "false");
                    } else {
                        alert('You need to sign in with Microsoft or Google');
                    }
                })
                .catch(error => console.error(error));
            };
            
            handleSubmitEmail();
        }
    }, [source, email]);

    const fields = [
        { 
            name: "OTP",
            type: 'text', 
            style: { backgroundColor: 'white' },
            placeholder: "Enter OTP",
        }
    ];

    const handleChange = (name, value) => {
        setFormData(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const otp = formData['OTP'] || formData[Object.keys(formData)[0]];
        const client_id = window.localStorage.getItem("clientID");
        
        const uploadData = new FormData();
        uploadData.append('email', email);
        uploadData.append('OTP', otp);

        fetch(`${process.env.REACT_APP_API_ENDPOINT}/authorise/login/`, {
            method: 'POST',
            body: uploadData
        })
        .then(data => data.json())
        .then(data => {
            if (data.token) {
                window.localStorage.setItem('loginStatus', 'true');
                window.localStorage.setItem("token", data.token);
                window.localStorage.setItem('phone', data.phone);
                onOTPLoginClickSuccessCT(email);
                setSignIn(true);
                setWrongOTP(false);
            } else {
                setSignIn(false);
                setWrongOTP(true);
            }
        })
        .catch(error => {
            setSignIn(false);
            alert('System Error. Contact Admin');
            console.error(error);
        });
    };

    if (window.localStorage.getItem('loginStatus') === 'true') {
        onUserLoginCT(
            window.localStorage.getItem("user_name") || email.split("@")[0],
            email,
            window.localStorage.getItem("gender_male") === "true" ? "M" : "F",
            window.localStorage.getItem("clientID")
        );

        if (source === 'newsletter') {
            navigate('/redirect/?source=newsletter');
        } else {
            const player_name = window.localStorage.getItem("player_name");
            if (player_name) {
                window.localStorage.setItem("ReportName", "Sectors_Company_Profile");
                return <Navigate to="/report" />;
            } else {
                navigate('/redirect');
            }
        }
    }

    return (
        <SignIndiv>
            <RHSdiv>
                <Benchmarksdiv>
                    <Logo src="/Images/BenchmarksNew.svg" alt="logo" />
                    <ByRedseerDiv>
                        <ByRedseerLogo src="/Images/byredseer.png" alt="byredseer" />
                    </ByRedseerDiv>
                </Benchmarksdiv>
                
                <Signupmiddiv>
                    <div>An OTP has been sent to:</div>
                    <EmailDisplay>{displayEmail}</EmailDisplay>
                    {wrongOTP && <ErrorText>Wrong OTP. Please try again.</ErrorText>}
                </Signupmiddiv>
                
                <FormDiv>
                    <StyledForm onSubmit={handleSubmit}>
                        <CustomForm fields={fields} onChange={handleChange} formData={formData} />
                        <StyledButton type="submit">Verify OTP</StyledButton>
                    </StyledForm>
                </FormDiv>
            </RHSdiv>
        </SignIndiv>
    );
};

export default Otp3;

// Styled Components
const SignIndiv = styled.div`
    display: flex;
    min-height: 100vh;
`;

const RHSdiv = styled.div`
    width: 100%;
`;

const Benchmarksdiv = styled.div`
    margin-top: 15vh;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    ${media.small`
        margin-top: 10vh;
    `}
`;

const Signupmiddiv = styled.div`
    padding: 20px;
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
    
    ${media.small`
        font-size: 16px;
        padding: 10px;
    `}
`;

const EmailDisplay = styled.div`
    font-weight: bold;
    margin: 10px 0;
    color: #0099fe;
    word-break: break-all;
    padding: 0 20px;
`;

const ErrorText = styled.div`
    color: red;
    margin-top: 10px;
    font-size: 16px;
    
    ${media.small`
        font-size: 14px;
    `}
`;

const FormDiv = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const StyledForm = styled.form`
    width: 100%;
    max-width: 400px;
    padding: 0 20px;
`;

const StyledButton = styled.button`
    width: 100%;
    background-color: #0099fe;
    line-height: 40px;
    border-radius: 50px;
    border: none;
    margin-top: 20px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    
    &:hover {
        background-color: #007acc;
    }
    
    ${media.small`
        font-size: 14px;
        line-height: 35px;
        margin-top: 15px;
    `}
`;

const Logo = styled.img`
    height: 40px;
    margin-bottom: 10px;
`;

const ByRedseerDiv = styled.div`
    display: flex;
    margin-top: 5px;
`;

const ByRedseerLogo = styled.img`
    height: 18px;
`;