import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import CustomForm from "../components/CustomForm/CustomForm";
import * as Msal from "msal";
import { Navigate, useNavigate } from "react-router-dom";
import MyContext from "../utils/contexts/MyContext";
import { useLocation } from "react-router-dom";
import { onNewsletterClickCT, onUserLoginCT } from "../utils/clevertap";
import { media } from "../utils/media";
import {
  onSignInPageClickCT,
  onLogoutLandingPageClickCT,
  onMSLoginClickCT,
  onGoogleLoginClickCT,
  onOTPLoginClickCT,
  onOTPLoginClickSuccessCT,
} from "../utils/clevertap";
import Footer from "../components/Footer";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const SignIn = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const { treeData, setTreeData } = React.useContext(MyContext);
  const [isChecked, setIsChecked] = useState(true);
  const [open, setOpen] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const search = useLocation().search;
  const isExternal =
    new URLSearchParams(search).get("is_external") === "true" && true;
  const val = new URLSearchParams(search).get("val");
  const key = new URLSearchParams(search).get("key");
  const ext_email=new URLSearchParams(search).get("email");
  const [formData, setFormData] = useState({
    "Email Address": ext_email || ""  
  });
  const fields = [
    {
      name: "Email Address",  // Must match the key in formData
      type: "email",
      style: { backgroundColor: "white" },
      placeholder: "Enter Your Work Email Id",
    },
  ];
  const IndustryDict = JSON.parse(window.localStorage.getItem("IndustryDict"));
  const navigate = useNavigate();
  useEffect(() => {
    if (window.localStorage.getItem("loginStatus") === "true") {
      console.log("Already logged in, redirecting...");
      navigate("/redirect");
    }
    onSignInPageClickCT();

  }, []);

  const handleChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSignup = () => {
    handleClose();
    navigate("/signup");
  };

  useEffect(() => {
    // onLogoutLandingPageClickCT()
    if (isExternal === true) {
      window.localStorage.setItem("clientID", 141);
      navigate(`/Report3/?val=${val}&key=${key}&is_external=true`);
    } else {
      if(window.localStorage.getItem("loginStatus") === "true"){
        const lastAccessed = window.localStorage.getItem("lastAccessed");
        if (lastAccessed && IndustryDict) {
          const redirectRep = IndustryDict[lastAccessed] || {};
          if (redirectRep.report_name && redirectRep.id) {
            navigate(
              `/Report3/?val=${redirectRep.report_name}&key=${
                redirectRep.id
              }&filter=${redirectRep.filter || ""}&filter_value=${
                redirectRep.filter_value || ""
              }`
            );
          }
        }
      }
    }
  }, [IndustryDict, isExternal, key, navigate, val]);

  const msalConfig = {
    auth: {
      clientId: "9a7ffe59-718e-40ee-b04e-d6f85b53f512",
      authority:
        "https://login.microsoftonline.com/common",
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
  };

  const msalInstance = new Msal.UserAgentApplication(msalConfig);

  const handleSubmit = () => {
    if (!isChecked) {
      setShowWarning(true);
      return;
    }
    onOTPLoginClickCT();
    let email = formData["Email Address"] || formData[Object.keys(formData)[0]];
    window.localStorage.setItem("email", email);
    // const isInternalUser = email.endsWith("@redseerconsulting.com");
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/authorise/login/?email=${email}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((data) => data.json())
      .then((data) => {
        // this.props.userLogin(data.token);
        if (data["otp_access"] === true) {
          setLoggedIn(true);
          console.log(loggedIn);
          console.log("data=", data["pseudo_email"]);
          //   props.userLogin(data['pseudo_email'])
          window.localStorage.setItem("pseudo_email", data["pseudo_email"]);
          //   props.setClientID(data['client_id'])
          window.localStorage.setItem("clientID", data["client_id"]);
          window.localStorage.setItem("unregistered", data["unregistered"]);
          window.localStorage.setItem("user_name", data["user_name"]);
          window.localStorage.setItem("is_admin", data["is_admin"]);
          if (data["investor_id"]) {
            window.localStorage.setItem("investor_id", data["investor_id"]);
          }
          if (data["gender_male"] !== true) {
            window.localStorage.setItem("gender_male", "false");
          } else {
            window.localStorage.setItem("gender_male", "true");
          }
          console.log(
            "gender_male = ",
            window.localStorage.getItem("gender_male")
          );
        } else {
          setOpen(true); // Show custom modal if account doesn't exist
        }
        // this.props.navigate('/reportlist')
      })
      .catch((error) => console.error(error));
  };

  const onGoogleLoginClick = useCallback(() => {
    if (!isChecked) {
      setShowWarning(true); // Show warning message
      return;
    }
    onGoogleLoginClickCT();
    const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";
    const redirectUri = "api/v1/auth/login/google/";

    const scope = [
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
    ].join(" ");

    const params = {
      response_type: "code",
      client_id:
        "560541008989-k0er5bb7onv6dj7d46fh05cjes2qb9p5.apps.googleusercontent.com",
      // redirect_uri: `http://localhost:8001/api/v1/auth/login/google/`,
      redirect_uri: `https://api.benchmarks.digital/api/v1/auth/login/google/`,
      prompt: "select_account",
      access_type: "offline",
      scope,
    };

    const urlParams = new URLSearchParams(params).toString();
    // window.localStorage.setItem('loginStatus', 'true')
    window.location = `${googleAuthUrl}?${urlParams}`;
  }, []);

  function acquireToken() {
    var tokenRequest = {
      scopes: ["user.read", "mail.send"],
    };

    msalInstance
      .acquireTokenSilent(tokenRequest)
      .then((response) => {
        console.log("rt=", response);
        let data = response;
        if (data) {
          console.log("acc=", data["account"].userName);
          let email = data["account"].userName;
          const uploadData = new FormData();
          uploadData.append("email", data["account"].userName);
          uploadData.append("access_token", data["accessToken"]);
          fetch(`${process.env.REACT_APP_API_ENDPOINT}/login/ms/`, {
            method: "POST",
            body: uploadData,
          })
            .then((data) => data.json())
            .then((data) => {
              if (data.token) {
                onUserLoginCT(
                  data.user_name !== undefined
                    ? data.user_name
                    : email.split("@")[0],
                  email,
                  data["gender_male"] ? "M" : "F",
                  data.client_id
                );
                console.log("data=", data);
                window.localStorage.setItem("loginStatus", "true");
                window.localStorage.setItem("token", data.token);
                window.localStorage.setItem("pseudo_email", data.pseudo_email);
                window.localStorage.setItem("clientID", data.client_id);
                window.localStorage.setItem("email", email);
                window.localStorage.setItem("user_name", data.user_name);
                window.localStorage.setItem("is_admin", data.is_admin);
                if (data.investor_id) {
                  window.localStorage.setItem("investor_id", data.investor_id);
                }
                if (data["gender_male"] !== true) {
                  window.localStorage.setItem("gender_male", "false");
                } else {
                  window.localStorage.setItem("gender_male", "true");
                }
                // fetch(`${process.env.REACT_APP_API_ENDPOINT}/newreports/?rep_id=9&client_id=${data.client_id}`, {
                //   method:'GET',
                //   headers:{
                //     'Content-Type': 'application/json',
                //   },
                // })
                // .then(res=>res.json())
                // .then(
                //   res=>{
                //     setTreeData(res)
                //     console.log(res)
                //   }
                // )

                navigate("/redirect");
              }
            })
            .catch((error) => {
              // setSignIn(false);
              alert("System Error.Contact Admin");
              console.log(error);
            });
        }
      })
      .catch((err) => {
        console.log("err= ", err);
        if (err instanceof Msal.InteractionRequiredAuthError) {
          // Fallback to interactive method via redirect
          msalInstance.acquireTokenRedirect(tokenRequest);
        } else {
          console.log("Another error:", err);
        }
      });
  }

  msalInstance.handleRedirectCallback((error, loginResponse) => {
    if (error) {
      console.error(error);
      return;
    }
    // User is logged in
    if (msalInstance.getAccount()) {
      acquireToken();
    }
  });

  const onMsLoginClick = () => {
    if (!isChecked) {
      setShowWarning(true); // Show warning message
      return;
    }
    onMSLoginClickCT();
    msalInstance.loginRedirect(["user.read"]);
  };

  const handleLogin = () => {
    console.log("msalInstance = ", msalInstance);
    msalInstance.loginPopup(["user.read"]).then(function (loginResponse) {
      // the user has successfully logged in
      // you can now get the user's access token using the getAccessToken method
      console.log("lr=", loginResponse);
      if (msalInstance.getAccount()) {
        var tokenRequest = {
          scopes: ["user.read", "mail.send"],
        };
        msalInstance
          .acquireTokenSilent(tokenRequest)
          .then((response) => {
            // get access token from response
            // response.accessToken
            console.log("rt=", response);
            let data = response;
            if (data) {
              console.log("acc=", data["account"].userName);
              let email = data["account"].userName;
              const uploadData = new FormData();
              uploadData.append("email", data["account"].userName);
              uploadData.append("access_token", data["accessToken"]);
              fetch(`${process.env.REACT_APP_API_ENDPOINT}/login/ms/`, {
                method: "POST",
                body: uploadData,
              })
                .then((data) => data.json())
                .then((data) => {
                  if (data.token) {
                    console.log("data=", data);
                    window.localStorage.setItem("loginStatus", "true");
                    window.localStorage.setItem("token", data.token);
                    window.localStorage.setItem(
                      "pseudo_email",
                      data.pseudo_email
                    );
                    window.localStorage.setItem("clientID", data.client_id);
                    window.localStorage.setItem("email", email);
                    window.localStorage.setItem("user_name", data.user_name);
                    if (data["gender_male"] !== true) {
                      window.localStorage.setItem("gender_male", "false");
                    } else {
                      window.localStorage.setItem("gender_male", "true");
                    }
                    navigate("/newreport");
                  } else {
                  }
                })
                .catch((error) => {
                  // setSignIn(false);
                  alert("System Error.Contact Admin");
                  console.log(error);
                });
            }
          })
          .catch((err) => {
            // could also check if err instance of InteractionRequiredAuthError if you can import the class.
            if (err.name === "InteractionRequiredAuthError") {
              return msalInstance
                .acquireTokenPopup(tokenRequest)
                .then((response) => {
                  // get access token from response
                  // response.accessToken
                })
                .catch((err) => {
                  // handle error
                });
            }
          });
      } else {
        console.log("wrong");
      }
      // msalInstance.acquireTokenSilent(params).then(response=>{
      //     console.log('at=', response.accessToken)
      // }).catch(err=>{console.log(err)})
    });
  };

  const useQuery = () => new URLSearchParams(useLocation().search);
  const newsletter_name = useQuery().get("newsletter_name") || "";
  const newsletter_month = useQuery().get("newsletter_month") || "";
  const newsletter_user = useQuery().get("newsletter_user") || "";
  const newsletter_email = useQuery().get("newsletter_email") || "";
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (
        newsletter_name &&
        newsletter_month &&
        newsletter_user &&
        newsletter_email
      ) {
        onNewsletterClickCT(
          newsletter_name,
          newsletter_month,
          "clicked",
          newsletter_email,
          "Signin Page"
        );
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, []);

  if (loggedIn) {
    console.log(loggedIn);
    return <Navigate to="/otp" />;
  }
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    if(e.target.checked === false){
      setShowWarning(true)
    }else{
      setShowWarning(false)
    }
  };
  return (
    <SignIndiv>
      <RHSdiv>
        <Benchmarksdiv>
          <Logo src="/Images/benchmarks_mail_logo.png" alt="logo" />
          {/* <ByRedseerDiv>
            <ByRedseerLogo src="/Images/byredseer.png" alt="byredseer" />
          </ByRedseerDiv> */}
          <TextDiv>
            A comprehensive business strategy tool for corporates, investors,
            and brands. Access by invitation only
          </TextDiv>
          <TextDivMobile>
            <div>
              A comprehensive business strategy tool for corporates, investors,
              and brands.
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "5px",
              }}
            >
              Access by invitation only
            </div>
          </TextDivMobile>
        </Benchmarksdiv>
        <div style={{
          marginBottom: "22px",
        }}>
          <DeskTopView>
            <input
              type="checkbox"
              id="terms"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            By signing in, you agree to our{" "}
            <a href="/terms-of-use" target="_blank" rel="noopener noreferrer">
              Terms of Use
            </a>{" "}
            and{" "}
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
            .
          </DeskTopView>
          {showWarning === true && (
            <WarningMessage>
              Please agree to the Terms of Use and Privacy Policy to proceed.
            </WarningMessage>
          )}
        </div>
        <FormDiv>
          <CustomForm fields={fields} onChange={handleChange} formData={formData} />
          <SigninButton onClick={handleSubmit} disabled={!isChecked}>
            Sign In
          </SigninButton>
        </FormDiv>
        <DividerContainer>
          <Divider>
            <Line />
            <OrText>Or</OrText>
            <Line />
          </Divider>
        </DividerContainer>
        <SocialLogin>
          <LoginButton onClick={onGoogleLoginClick} disabled={!isChecked}>
            <Icon src="/Images/google_icon.png" alt="Google" /> Sign In with
            Work Email
          </LoginButton>
          <LoginButton onClick={onMsLoginClick} disabled={!isChecked}>
            <Icon src="/Images/ms_icon.png" alt="Microsoft" /> Sign In with Work
            Email
          </LoginButton>
        </SocialLogin>
      </RHSdiv>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Let's Get You Started!</DialogTitle>
        <DialogContent>
          We couldn't find your account in our system. If you're a new user,
          request an invite to gain access.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSignup} variant="contained" color="primary">
            Request Invite
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </SignIndiv>
  );
};

export default SignIn;

const SigninButton = styled.button`
  margin-left: 0%;
  width: 100%;
  background-color: #0099fe;
  line-height: 40px;
  border-radius: 50px;
  border: none;
  margin-top: 20px;
  color: white;
  cursor: pointer;
  ${media.small`
    font-size: 12px;
    line-height: 30px;
  `}
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const SignIndiv = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  align-content: center;
`;

const RHSdiv = styled.div`
  z-index: 100;
  position: relative;
  width: 100%;
  height: 100%;
  ${media.small`
    flex: 1;
    width: 100%;
    height: 100vh;
  `}
`;

const Benchmarksdiv = styled.div`
  margin-top: 15vh;
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.small`
    margin-top: 25vh;
  `}
`;

const Logo = styled.img`
  height: 50px;
`;

const ByRedseerDiv = styled.div`
  display: flex;
  margin-left: 170px;
`;

const ByRedseerLogo = styled.img`
  height: 20px;
`;

const TextDiv = styled.div`
  margin-bottom: -23px;
  display: flex;
  align-content: center;
  align-items: center;
  padding: 15px;
  ${media.small`
    display: none;
    padding: 8px;
    margin-left: 20px;
    font-size: 10px;
  `}
`;

const TextDivMobile = styled.div`
  margin-bottom: -23px;
  display: flex;
  align-content: center;
  align-items: center;
  padding: 15px;
  display: none;
  ${media.small`
    display:block;
    margin-left: 18px;
    font-size: 9px;
  `}
`;

const SocialLogin = styled.div`
  margin-bottom: 30px;
  height: 12vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${media.small`
    margin-bottom: 30px;
    height: 8vh;
    font-size: 11px;
  `}
`;

const LoginButton = styled.button`
  width: 20%;
  height: 100%;
  border: 1px solid rgb(204, 204, 204);
  cursor: pointer;
  margin-bottom: 1.2%;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0.5%;
  white-space: nowrap;
  background-color: white;
  ${media.small`
    width: 60%;
    padding: 3%;
    border-radius: 80px;
  `}
  ${media.medium`
    width: 60%;
    padding: 1%;
    border-radius: 80px;
  `}
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 3%;
`;

const Divider = styled.div`
  display: flex;
  align-items: center;
  width: 20%;
  justify-content: center;
  margin-bottom: 1%;
  ${media.small`
    margin-bottom: 2%;
    width: 59%;
  `}
`;

const Line = styled.span`
  flex: 1;
  height: 1px;
  background-color: rgb(204, 204, 204);
`;

const OrText = styled.button`
  border: none;
  background: none;
  margin: 0 10px;
  white-space: nowrap;
  cursor: default;
`;

const FormDiv = styled.div`
  margin-top: 9px;
  width: 20%;
  margin-left: 40%;
  align-items: center;
  align-content: center;
  ${media.small`
    width: 60%;
    margin-left: 20%;
  `}
`;

const DividerContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${media.small`
    margin-top: -12px;
    height: 8vh;
    font-size: 11px;
  `}
`;

const DeskTopView = styled.div`
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border: 1px solid #d0d7de;
  border-radius: 8px;
  padding: 12px 16px;
  background-color: #f6f8fa;
  min-width: fit-content;
  width: 30%;
  margin: 0 auto;
  line-height: 1.5;
  text-align: center;
  flex-wrap: nowrap;
  white-space: nowrap;

  input[type="checkbox"] {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  a {
    color: #0073e6;
    text-decoration: none;
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }
  }

  ${media.small`
    font-size: 10px;
    flex-direction: row;
    align-items: center;
    text-align: center;
    padding: 8px;
    gap: 2px;
    margin-bottom: 22px;
    input[type="checkbox"] {
    width: 10px;
    height: 10px;
    cursor: pointer;
  }
  `}
`;

const WarningMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 8px;
  text-align: center;
   ${media.small`
    font-size: 10px;
    margin-top: -12px;
  }
  `}
`;
