import { ResponsiveLine } from "@nivo/line";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import MasterTable from "../../../components/MasterTable";
import { Pagination } from "antd";
import { media } from "../../../utils/media";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";

const TableComponent = ({ data }) => {
  const [isScale, setScale] = useState(false);
  const [isCurrency, setCurrency] = useState(false);
  const [title, setTitle] = useState(data?.Title || "");
  const [isUSDPresent, setIsUSDPresent] = useState(false);
  const [usdToINRRate, setUsdToINRRate] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [canScrollHorizontally, setCanScrollHorizontally] = useState(false);
  const tableContainerRef = useRef(null);
  const is_usd = false;
  const isPercentage = data?.Title?.includes("%");
  const isINR = data?.Title?.includes("INR");
  const isHashTitle = data?.Title?.includes("#");
  const safeData = data || {};
  const safeValues = safeData.Values || [];
  const maxValue = useMemo(() => {
    try {
      const allValues = safeValues.flatMap(v => v?.Data || []);
      return allValues.length > 0 ? Math.max(...allValues) : 0;
    } catch (e) {
      return 0;
    }
  }, [safeValues]);
  const isMnScale = useMemo(() => {
    return (isHashTitle && maxValue >= 1_000_000) || 
           (isUSDPresent && maxValue >= 1_000_000);
  }, [isHashTitle, isUSDPresent, maxValue]);
  const isCrScale = useMemo(() => {
    return isINR && maxValue >= 10_000_000 && !isUSDPresent;
  }, [isINR, maxValue, isUSDPresent]);

  useEffect(() => {
    if (!data?.Title) return;
    const usdMatch = data.Title.match(/USD=([\d.]+)\s*INR/);
    if (usdMatch) {
      setIsUSDPresent(true);
      setUsdToINRRate(parseFloat(usdMatch[1]));
      if (maxValue >= 1_000_000) {
        setTitle(data.Title.replace(/USD=[\d.]+ INR/, `USD=${usdMatch[1]} INR (USD Mn)`));
        setScale(true);
      } else {
        setTitle(data.Title);
      }
    } else {
      setTitle(data?.Title);
    }
  }, [data?.Title, isUSDPresent]);

  const handleScroll = (direction) => {
    if (tableContainerRef.current) {
      const container = tableContainerRef.current;
      const scrollAmount = direction === 'left' ? -300 : 300;
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };
  
  const capitalizeFirst = str =>
    str ? str.charAt(0).toUpperCase() + str.slice(1) : "";
  const formatTooltipValue = (value, series) => {
    const prefix = capitalizeFirst(series?.Prefix || "");
    const postfix = capitalizeFirst(series?.Postfix || "");
    const hasCustomFormatting = prefix || postfix;

    let scaledValue, formattedValue, unit;
    if (isPercentage) {
      scaledValue = value;
      unit = "%";
    }else if (isMnScale) {
      scaledValue = value / 1_000_000;
      unit = "Mn";
    } else if (isCrScale) {
      scaledValue = value / 10_000_000;
      unit = "Cr";
    } else {
      scaledValue = value;
      unit = series?.Unit ? capitalizeFirst(series.Unit) : "";
    }

    formattedValue = scaledValue.toLocaleString('en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    if (isUSDPresent && isMnScale && !hasCustomFormatting) {
      formattedValue = `$ ${formattedValue}`;
    } else if (isCrScale && !hasCustomFormatting) {
      formattedValue = `₹ ${formattedValue}`;
    }

    return hasCustomFormatting
      ? `${prefix} ${formattedValue} ${postfix}`
      : `${formattedValue} ${unit}`;
  };
  
  const normalizedData = useMemo(() => {
    if (!data) return null;
    
    if (data.isCombined && Array.isArray(data.charts)) {
      return {
        ...data.charts[0],
        Values: data.charts.flatMap(chart => chart.Values),
        Title: "Combined Data View"
      };
    }
    return data;
  }, [data]);
  useEffect(() => {
    const checkScroll = () => {
      if (tableContainerRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = tableContainerRef.current;
        setShowLeftArrow(scrollLeft > 0);
        setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
        setCanScrollHorizontally(scrollWidth > clientWidth);
      }
    };

    const container = tableContainerRef.current;
    if (container) {
      checkScroll();
      container.addEventListener('scroll', checkScroll);
      window.addEventListener('resize', checkScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', checkScroll);
        window.removeEventListener('resize', checkScroll);
      }
    };
  }, [normalizedData]);
  const totalLength = normalizedData?.Labels?.Data?.length || 0;
  const currentData = useMemo(() => {
    return normalizedData?.Labels?.Data?.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    ) || [];
  }, [normalizedData, currentPage, rowsPerPage]);

  const columnsData = useMemo(() => {
    if (!normalizedData?.Values) return [];
    
    const baseColumns = [{
      key: "period",
      value: <ColumnText>{currentData.some(item => /\d{4}-\d{2}-\d{2}/.test(item) || /(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)/i.test(item)) && "Period"}</ColumnText>,
      sorting: false,
      min_width: "100px",
      fontSize: "16px",
      show: currentData.some(item => /\d{4}-\d{2}-\d{2}/.test(item) || /(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)/i.test(item))
    }];
    
    return [
      ...baseColumns,
      ...normalizedData.Values.map(series => ({
        key: series.Legend,
        value: <ColumnText>{series.Legend}</ColumnText>,
        sorting: false,
        min_width: "120px",
        fontSize: "16px",
        suffix: series.Postfix ? ` (${series.Postfix})` : ""
      }))
    ];
  }, [normalizedData, currentData]);

  const styledRowsData = useMemo(() => {
    if (!normalizedData?.Values || !normalizedData.Labels?.Data) return [];
    
    return currentData.map((date, index) => {
      const originalIndex = (currentPage - 1) * rowsPerPage + index;
      const rowData = {
        period: {
          value: <TextCell>{date}</TextCell>
        }
      };
      
      normalizedData.Values.forEach(series => {
        const value = series.Data[originalIndex];
        rowData[series.Legend] = {
          value: <TextCell>{formatTooltipValue(value, series)}</TextCell>
        };
      });

      return rowData;
    });
  }, [currentData, currentPage, normalizedData, rowsPerPage, isUSDPresent]);

  const PaginationControls = () => {
    if (totalLength <= 10) return null;    
    return (
      <div style={{ 
        marginTop: '16px',
        position: 'relative',
        zIndex: 10
      }}>
        <PaginationContainer>
          <Pagination
            current={currentPage}
            pageSize={rowsPerPage}
            total={totalLength}
            onChange={(page, pageSize) => {
              setCurrentPage(page);
              setrowsPerPage(pageSize);
            }}
            showSizeChanger
            pageSizeOptions={['10', '20', '50', '100']}
          />
        </PaginationContainer>
      </div>
    );
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      margin: '0 auto',
      overflow: 'hidden',
      position: 'relative'
    }}>
      {showLeftArrow && (
        <ScrollArrow 
          direction="left" 
          onClick={() => handleScroll('left')}
          aria-label="Scroll left"
        >
          <FaRegArrowAltCircleLeft size={24} />
        </ScrollArrow>
      )}
      
      <div 
        ref={tableContainerRef}
        style={{ 
          borderRadius: '8px',
          overflowX: 'auto',
          width: '100%',
          position: 'relative',
          scrollbarWidth: 'thin'
        }}
      >
        <div style={{ 
          padding: '16px',
          minWidth: `${columnsData.length * 120}px` // Ensure minimum width based on columns
        }}>
          <MasterTable
            columnsData={columnsData}
            rowsData={styledRowsData}
            headerBackground="#FFFFFF"
            autoCellHeight={true}
            toolGap={-4}
            loading={false}
          />
        </div>
      </div>
      
      {showRightArrow && (
        <ScrollArrow 
          direction="right" 
          onClick={() => handleScroll('right')}
          aria-label="Scroll right"
        >
          <FaRegArrowAltCircleRight size={24} />
        </ScrollArrow>
      )}
      <PaginationControls />
    </div>
  );
};

export default TableComponent;

const ColumnText = styled.div`
  font-size: ${props => props.fontSize || '16px'};
  font-weight: 500;
  color: #333;
`;

const TextCell = styled.div`
  font-weight: 400;
  color: #333;
  font-size: 14px;
  padding: 4px 0;
  line-height: 1.4;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  zIndex: 10;
  cursor: pointer;
  .ant-pagination {
    font-size: 14px;
  }
  
  ${media.small`
    .ant-pagination-item,
    .ant-pagination-options {
      margin: 0 2px;
    }
  `}
`;

const ScrollArrow = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${props => props.direction === 'left' ? 'left: 0;' : 'right: 0;'}
  z-index: 20;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ddd;
  border-radius: ${props => props.direction === 'left' ? '0 4px 4px 0' : '4px 0 0 4px'};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  color: #333;
  
  &:hover {
    background-color: rgba(240, 240, 240, 0.95);
    color: #000;
  }
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
`;
const ScrollHint = styled.div`
  text-align: center;
  margin-top: 5px;
  color: #666;
  font-size: 12px;
  padding: 5px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
   ${media.small`
      bottom: -2px;
    `}
`;