import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import MasterTable from "../../components/MasterTable";
import { media } from "../../utils/media";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { chatHisrtoryData, fetchChatHistoryData, fetchClientsIdsData, selectnewClientIdsData } from "../../utils/redux/slices";
import { Button, DatePicker, Pagination } from "antd";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import TransformedNivoLineGraph from "./TransformedLineGraph";
import TransformedNivoBarGraph from "./TransformedBarGraph";
import FeedbackTextWithToggle from "./FeedBackWithToggle";
import TableComponent from "../../components/SearchBar/TableComponent";
import TransformedStackBar from "../../components/TransformedStackedBar";
import TransformedTable from "./TransformedTable";
import Loader from "../../components/Loader";
import TransformedNivoRibbonChart from "../../components/TransformedNivoRibbonChart";
const { RangePicker } = DatePicker;
const ChatHistory = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [selectedChartData, setSelectedChartData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [parameterRange, setParameterRange] = useState({});
  const [parameterValueFilter, setParameterValueFilter] = useState({});
  const [sortConfig, setSortConfig] = useState({
    key: 'date',
    direction: 'desc'
  });
  const [dateRange, setDateRange] = useState([]);
  const chatHistoryData = useSelector(chatHisrtoryData);
  const clientIdsData = useSelector(selectnewClientIdsData);
  const is_admin = window.localStorage.getItem("is_admin") === "true" ? true : false;
  const [compSearchString, setCompSearchString] = useState("");
  const [visibleData, setVisibleData] = useState([]);
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [columnsData, setColumnsData] = useState([]);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const dateFilterRef = useRef(null);
  const [styledRowsData, setStyledRowsData] = useState([]);
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const showEndPicker = windowWidth <= 480;
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [windowWidth]);
  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };
  const DateRangeFilter = () => (
    <DropdownWrapper>
      <RangePicker
        format="MM/DD/YYYY"
        value={dateRange}
        onChange={handleDateRangeChange}
        className={
          showEndPicker
            ? "custom-range-picker-dropdown show-end"
            : "custom-range-picker-dropdown"
        }
        style={{ width: "80%", height: windowWidth <= "768" ? "" : "40px", borderRadius: "8px" }}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        key={windowWidth}
      />
    </DropdownWrapper>
  )
  useEffect(() => {
    if (chatHistoryData && chatHistoryData.length > 0) {
      const clientFilterOptions = [];
      const clientMap = new Map();
      chatHistoryData.forEach(item => {
        if (item.client_id && !clientMap.has(item.client_id)) {
          const client = clientIdsData?.find(c => c.id === item.client_id);
          clientFilterOptions.push({
            id: item.client_id,
            name: client?.name || item.client_id,
            selected: false
          });
          clientMap.set(item.client_id, true);
        }
      });

      const emailFilterOptions = [...new Set(chatHistoryData.map(item => item.email))]
        .filter(email => email !== null && email !== "")
        .map((email, index) => ({
          id: index,
          name: email,
          selected: false
        }));
      const categoryFilterOptions = [...new Set(chatHistoryData.map(item => item.data_source))]
        .filter(source => source !== null && source !== "")
        .map((source, index) => ({
          id: index,
          name: source,
          selected: false
        }));

      // const responseTimeValues = [...new Set(chatHistoryData.map(item => item.query_response_time))]
      // .filter(query_response_time => query_response_time !== null && query_response_time !== "")
      // .map((query_response_time, index) => ({
      //   id: index,
      //   name: query_response_time,
      //   selected: false
      // }));
      const responseTimes = chatHistoryData
        .map(item => parseInt(item.query_response_time, 10))
        .filter(time => !isNaN(time));
      const minTime = Math.min(...responseTimes);
      const maxTime = Math.max(...responseTimes);
      const numberOfBuckets = 5;
      const bucketSize = Math.ceil((maxTime - minTime + 1) / numberOfBuckets);
      const responseTimeBuckets = [];
      for (let i = 0; i < numberOfBuckets; i++) {
        const start = minTime + i * bucketSize;
        const end = Math.min(start + bucketSize - 1, maxTime);
        responseTimeBuckets.push({
          id: i,
          name: `${start}–${end} s`,
          selected: false,
          range: [start, end]
        });
      }
      const feedbackFilterOptions = [
        { id: 'positive', name: 'Positive', selected: false },
        { id: 'negative', name: 'Negative', selected: false },
        { id: 'none', name: 'No Feedback', selected: false }
      ];

      const columns = [
        {
          key: "question",
          value: <ColumnText>Question</ColumnText>,
          sorting: false,
          range_filter: false,
          value_filter: false,
          min_width: "150px",
          fontSize: "12px"
        },
        {
          key: "answer",
          value: <ColumnText>Answer</ColumnText>,
          sorting: false,
          range_filter: false,
          value_filter: false,
          min_width: "150px",
          fontSize: "12px"
        },
        {
          key: "email",
          value: <ColumnText>Email</ColumnText>,
          sorting: false,
          range_filter: false,
          value_filter: true,
          value_filter_data: emailFilterOptions,
          min_width: "60px",
          fontSize: "12px"
        },
        {
          key: "client_id",
          value: <ColumnText>Client Name</ColumnText>,
          sorting: true,
          range_filter: false,
          value_filter: true,
          value_filter_data: clientFilterOptions,
          min_width: "60px",
          fontSize: "12px"
        },
        {
          key: 'date',
          value: (
            <ColumnText style={{ cursor: 'pointer' }} onClick={() => handleSort('date')}>
              Date {sortConfig.key === 'date' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </ColumnText>
          ),
          sorting: false,
          range_filter: false,
          value_filter: false,
          min_width: '60px',
          fontSize: '12px',
        },
        {
          key: 'time',
          value: (
            <ColumnText style={{ cursor: 'pointer' }} onClick={() => handleSort('time')}>
              Time {sortConfig.key === 'time' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </ColumnText>
          ),
          sorting: false,
          min_width: '60px',
          fontSize: '12px'
        },
        {
          key: "query_response_time",
          value: <ColumnText>Response Time</ColumnText>,
          sorting: false,
          range_filter: false,
          min_width: "60px",
          fontSize: "12px",
          value_filter: true,
          value_filter_data: responseTimeBuckets,
        },
        {
          key: 'data_source',
          value: <ColumnText>Category</ColumnText>,
          sorting: false,
          range_filter: false,
          value_filter: true,
          value_filter_data: categoryFilterOptions,
          min_width: "60px",
          fontSize: "12px"
        },
        {
          key: "feedback",
          value: <ColumnText>Feedback</ColumnText>,
          sorting: false,
          range_filter: false,
          value_filter: true,
          value_filter_data: feedbackFilterOptions,
          min_width: "0px",
          fontSize: "12px"
        }
      ];

      setColumnsData(columns);
      setVisibleData(chatHistoryData);
      setDataLoaded(true);
      const initialValueFilters = {};
      columns.forEach(col => {
        if (col.value_filter) {
          initialValueFilters[col.key] = col.value_filter_data;
        }
      });
      setParameterValueFilter(initialValueFilters);
    }
  }, [chatHistoryData, clientIdsData]);
  useEffect(() => {
    if (isDataLoaded && chatHistoryData) {
      let filteredData = [...chatHistoryData];

      if (compSearchString) {
        filteredData = filteredData.filter(item =>
          item.question?.toLowerCase().includes(compSearchString.toLowerCase()) ||
          (item.answer && item.answer.toLowerCase().includes(compSearchString.toLowerCase()))
        );
      }

      if (dateRange && dateRange[0] && dateRange[1]) {
        const startDate = new Date(dateRange[0]);
        const endDate = new Date(dateRange[1]);

        filteredData = filteredData.filter(item => {
          const itemDate = new Date(item.created);
          return itemDate >= startDate && itemDate <= endDate;
        });
      }

      Object.keys(parameterValueFilter).forEach(key => {
        const selectedFilters = parameterValueFilter[key].filter(opt => opt.selected);
        if (selectedFilters.length > 0) {
          if (key === 'feedback') {
            filteredData = filteredData.filter(item =>
              selectedFilters.some(filter => {
                if (filter.id === 'positive') return item.positive_feedback > 0;
                if (filter.id === 'negative') return item.negative_feedback > 0;
                if (filter.id === 'none') return !item.positive_feedback && !item.negative_feedback;
                return false;
              })
            );
          } else if (key === 'query_response_time') {
            filteredData = filteredData.filter(item => {
              const responseTime = parseInt(item.query_response_time, 10);
              return selectedFilters.some(filter => {
                const [start, end] = filter.range || [];
                return responseTime >= start && responseTime <= end;
              });
            });
          } else {
            filteredData = filteredData.filter(item =>
              selectedFilters.some(filter =>
                key === 'client_id' ?
                  item[key] === filter.id :
                  item[key] === filter.name
              )
            );
          }
        }
      });
      Object.keys(parameterRange).forEach(key => {
        if (parameterRange[key]) {
          const { start, end } = parameterRange[key];
          if (key === 'query_response_time') {
            filteredData = filteredData.filter(item => {
              const responseTime = Number(item.query_response_time);
              return responseTime >= start && responseTime <= end;
            });
          }
        }
      });

      setVisibleData(filteredData);
    }
  }, [compSearchString, parameterValueFilter, parameterRange, isDataLoaded, chatHistoryData, dateRange]);
  const onValueFilterApply = (parameter_id, valueFilter) => {

    setParameterValueFilter(prev => ({
      ...prev,
      [parameter_id]: valueFilter
    }));
  };
  const onRangeFilterApply = (parameter_id, selectedRange) => {
    setParameterRange(prev => ({
      ...prev,
      [parameter_id]: selectedRange
    }));
  };

  const showModal = (answer) => {
    try {
      const parsedAnswer = JSON.parse(answer);
      if (Array.isArray(parsedAnswer) && parsedAnswer.length > 0 && Array.isArray(parsedAnswer[0])) {
        const chartDataArray = parsedAnswer[0];
        setSelectedAnswer(
          chartDataArray.map(item => item.insightText || item.Title || "No insight text available")
            .filter(text => text)
            .join('\n\n')
        );
        setSelectedChartData(chartDataArray);
        setIsModalVisible(true);
        return;
      }

      // Handle reular array of charts
      if (Array.isArray(parsedAnswer)) {
        setSelectedAnswer(
          parsedAnswer.map(item => item.insightText || item.Title || "No insight text available")
            .filter(text => text)
            .join('\n\n')
        );
        setSelectedChartData(parsedAnswer);
        setIsModalVisible(true);
        return;
      }
      if (parsedAnswer?.data?.Type || parsedAnswer?.Chart) {
        setSelectedAnswer(parsedAnswer.insightText || parsedAnswer.Title || "No insight text available");
        setSelectedChartData(parsedAnswer.data || parsedAnswer.Chart);
      } else {
        setSelectedAnswer(answer);
        setSelectedChartData(null);
      }
    } catch (e) {
      setSelectedAnswer(answer);
      setSelectedChartData(null);
    }
    setIsModalVisible(true);
  };

  useEffect(() => {
    if (isModalVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalVisible]);

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedAnswer("");
    setSelectedChartData(null);
  };

  const truncateText = (text, limit = 30) => {
    if (!text) return "";
    if (text.length <= limit) return text;
    return `${text.substring(0, limit)}...`;
  };

  useEffect(() => {
    dispatch(fetchChatHistoryData())
  }, [dispatch]);

  const handleSort = (key) => {
    if (key === 'date' || key === 'time') {
      setSortConfig(prev => ({
        key,
        direction: prev.key === key
          ? prev.direction === 'asc' ? 'desc' : 'asc'
          : 'desc'
      }));
    }
  };

  useEffect(() => {
    if (chatHistoryData?.length > 0) {
      setLoading(true);
      const sortedData = [...visibleData].sort((a, b) => {
        const dateA = new Date(a.created);
        const dateB = new Date(b.created);

        if (sortConfig.key === 'date' || sortConfig.key === 'time') {
          return sortConfig.direction === 'asc'
            ? dateA - dateB
            : dateB - dateA;
        }
        return 0;
      });
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const paginatedData = sortedData.slice(startIndex, endIndex);
      const clientNameMap = clientIdsData?.reduce((acc, client) => {
        acc[client.id] = client.name;
        return acc;
      }, {});
      const formattedData = paginatedData.map((item) => {
        const clientName = clientNameMap?.[item?.client_id] || item?.client_id;
        return {
          client_id: {
            value: <TextCell>{clientName}</TextCell>
          },
          email: {
            value: <TextCell>{item.email || "-"}</TextCell>
          },
          question: {
            value: <TextCell>{item.question}</TextCell>
          },
          answer: {
            value: (
              <AnswerCell>
                {truncateText(item.answer)}
                {item.answer && item.answer.length > 50 ? (
                  <ViewButton onClick={() => showModal(item.answer)}>
                    View
                  </ViewButton>
                ) : (
                  item.answer === null || item.answer === "" ? "-" : null
                )}
              </AnswerCell>
            )
          },
          date: {
            value: (
              <TextCell>
                {moment(item.created).format("Do MMM YYYY")}
              </TextCell>
            )
          },
          time: {
            value: (
              <TextCell>
                {moment(item.created).format("h:mm a")}
              </TextCell>
            )
          },
          query_response_time: {
            value: <TextCell>{item.query_response_time ? `${item.query_response_time} s` : "-"}</TextCell>
          },
          data_source: {
            value: <TextCell>{item.data_source || "-"}</TextCell>
          },
          feedback: {
            value: (
              <FeedbackCell>
                {item.positive_feedback > 0 && (
                  <PositiveFeedback>👍</PositiveFeedback>
                )}
                {item.negative_feedback > 0 && (
                  <NegativeFeedback>
                    👎{' '}
                    {item.feedback && item.feedback.length > 50 ? (
                      <FeedbackTextWithToggle text={item.feedback} />
                    ) : (
                      item.feedback || '-'
                    )}
                  </NegativeFeedback>
                )}
                {!item.positive_feedback && !item.negative_feedback && "-"}
              </FeedbackCell>
            )
          }
        };
      });

      setStyledRowsData(formattedData);
      setTimeout(() => {
        setLoading(false);
      }, 1000)
    }
  }, [chatHistoryData, currentPage, pageSize, clientIdsData, sortConfig, visibleData]); // Added visibleData dependency

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleCancel();
      }
    };
    if (isModalVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalVisible]);

  useEffect(() => {
    dispatch(fetchClientsIdsData());
  }, [dispatch]);

  useEffect(() => {
    if (is_admin === false) {
      navigate("/internet-landscape");
    }
  }, [is_admin, navigate])
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dateFilterRef.current && !dateFilterRef.current.contains(event.target)) {
        setShowDateFilter(false);
      }
    };

    if (showDateFilter) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDateFilter]);
  const formatTitle = (title, chartData) => {
    if (!title) return title;
    if (
      (title.includes("USD=") && (title.includes("(USD Mn)") || title.includes("(INR Cr)"))) ||
      (title.includes("(INR)") && title.includes("(INR Cr)"))
    ) {
      return title;
    }
    let usdRate = "";
    const usdMatch = title.match(/USD=([\d.]+)\s*INR/);
    if (usdMatch) {
      usdRate = `USD=${usdMatch[1]} INR`;
    }
    let unit = "";
    if (chartData?.Values) {
      const maxValue = Math.max(...chartData.Values.flatMap((v) => v.Data));

      if (usdMatch && maxValue >= 1_000_000) {
        unit = "(USD Mn)";
      } else if (title.includes("INR") && maxValue >= 10_000_000) {
        unit = "(INR Cr)";
      } else if (title.includes("(#)") && maxValue >= 1_000_000) {
        unit = "(Mn)";
      }
    }

    let newTitle = title;
    newTitle = newTitle.replace(/\(USD=[\d.]+ INR\)/, "").trim();
    if (unit) {
      newTitle = newTitle.replace(/\((USD Mn|INR Cr|# Mn|#)\)/, "").trim();
    }
    newTitle = newTitle.replace(/\(INR\)/, "").trim();

    if (chartData?.Values && title.includes("(#)")) {
      const maxValue = Math.max(...chartData.Values.flatMap((v) => v.Data));
      if (maxValue >= 1_000_000) {
        newTitle = newTitle.replace(/\(#\)/, "(# Mn)");
      }
    }
    if (usdRate && unit) {
      return `${newTitle} (${usdRate}) ${unit}`;
    } else if (usdRate) {
      return `${newTitle} (${usdRate})`;
    } else if (unit) {
      return `${newTitle} ${unit}`;
    }

    return newTitle;
  };

  return (
    <PageWrapper>
      <Header />

      <GridWraper>
        {isDataLoaded ? (
          <>
            <FiltersContainer>
              <DateRangeFilter />
            </FiltersContainer>
            <MasterTable
              columnsData={columnsData}
              rowsData={styledRowsData}
              headerBackground="#FFFFFF"
              autoCellHeight={true}
              toolGap={-4}
              loading={loading}
              onRangeFilterApply={onRangeFilterApply}
              filteredRange={parameterRange}
              valueFilterData={parameterValueFilter}
              onValueFilterApply={onValueFilterApply}
              sortedParameter={sortConfig.key}
              setSortedParameter={(key) => handleSort(key)}
              sortedDirection={sortConfig.direction}
              setSortedDirection={(dir) => setSortConfig(prev => ({ ...prev, direction: dir }))}
            />
            {visibleData.length > 0 && (
              <PaginationContainer>
                <Pagination
                  current={currentPage}
                  pageSize={pageSize}
                  total={visibleData.length}
                  onChange={(page, size) => {
                    setCurrentPage(page);
                    setPageSize(size);
                  }}
                  showSizeChanger
                  pageSizeOptions={['10', '20', '50', '100']}
                />
              </PaginationContainer>
            )}
          </>
        ) : (
          <Loader />
        )}
      </GridWraper>
      <Footer />
      {isModalVisible &&
        <ModalContainer>
          <ModalContent ref={modalRef}>
            <ModalHeader>
              <h2>Detailed Answer</h2>
              <CloseButton onClick={handleCancel}>
                <MdClose size={24} />
              </CloseButton>
            </ModalHeader>
            <ModalBody>
              {Array.isArray(selectedChartData) ? (
                selectedChartData.map((chartData, index) => (
                  <React.Fragment key={index}>
                    {chartData?.isMultiChart && Array.isArray(chartData?.data) && (
                      <>
                        {chartData?.data?.map((multiData, idx) => (
                          <React.Fragment key={idx}>
                            <ChartTitle>{formatTitle(multiData?.Title, chartData)}</ChartTitle>
                            <ChartContainer>
                              {multiData?.Type === "Line" ? (
                                <TransformedNivoLineGraph data={multiData} />
                              ) : multiData?.Type === "Bar" ? (
                                <TransformedNivoBarGraph data={multiData} />
                              ): multiData?.Type === "Area" ? (
                                <TransformedNivoRibbonChart data={multiData} />
                              ) : multiData?.Type === "Stacked Bar" ? (
                                <TransformedStackBar data={multiData} />
                              ) : multiData?.Type === "Table" ? (
                                <TransformedTable data={multiData} />
                              ) : null}
                            </ChartContainer>
                            {idx === 0 && <TableComponent data={chartData && chartData?.data[0]} />}
                          </React.Fragment>
                        ))}
                        {selectedChartData?.insightText && (
                          <InsightText>
                            <div dangerouslySetInnerHTML={{
                              __html: selectedChartData.insightText
                                .replace(/\n/g, '<br/>')
                                .replace(/- /g, '• ')
                                .replace(/\*(\d[\d,.BMK]+)\*/g, '<strong>$1</strong>')
                                .replace(/(INR)/gi, '<strong>$1</strong>')
                            }} />
                          </InsightText>
                        )}
                      </>
                    )}
                  </React.Fragment>
                ))
              ) : selectedChartData ? (
                <>
                  <ChartTitle>{formatTitle(selectedChartData?.Title, selectedChartData)}</ChartTitle>
                  <ChartContainer>
                    {selectedChartData?.Type === "Line" ? (
                      <TransformedNivoLineGraph data={selectedChartData} />
                    ) : selectedChartData?.Type === "Bar" ? (
                      <TransformedNivoBarGraph data={selectedChartData} />
                    ) : selectedChartData?.Type === "Stacked Bar" ? (
                      <TransformedStackBar data={selectedChartData} />
                    ):selectedChartData?.Type === "Area" ? (
                      <TransformedNivoRibbonChart data={selectedChartData} />
                    ) : selectedChartData?.Type === "Table" ? (
                      <TransformedTable data={selectedChartData} />
                    ) : null}
                  </ChartContainer>
                  <TableComponent data={selectedChartData} />
                  {selectedChartData?.insightText && (
                    <InsightText>
                      <div dangerouslySetInnerHTML={{
                        __html: selectedChartData.insightText
                          .replace(/\n/g, '<br/>')
                          .replace(/- /g, '• ')
                          .replace(/\*(\d[\d,.BMK]+)\*/g, '<strong>$1</strong>')
                          .replace(/(INR)/gi, '<strong>$1</strong>')
                      }} />
                    </InsightText>
                  )}
                </>
              ) : null}
              <AnswerText>{selectedAnswer}</AnswerText>
            </ModalBody>
            <ModalFooter>
              <Button key="back" onClick={handleCancel}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalContainer>
      }
    </PageWrapper>
  );
};
export default ChatHistory;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #eef9ff;
  min-height: 100vh;
`;

const GridWraper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 3.5vw;
  margin-bottom: 10px;
  min-height: 85vh;
  gap: 20px;
  ${media.small`
    margin-bottom: 10px;
    padding: 15px;
  `}
`;

const TextCell = styled.div`
  font-weight: 400;
  color: #333;
  font-size: 14px;
  padding: 4px 0;
  line-height: 1.4;
  ${media.small`
    font-size: 12px;
  `}
`;

const AnswerCell = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ViewButton = styled.button`
  background: #1890ff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    background: #40a9ff;
  }
`;

const FeedbackCell = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const PositiveFeedback = styled.span`
  color: #10b981;
  font-weight: 500;
`;

const NegativeFeedback = styled.span`
  color: #ef4444;
  font-weight: 500;
`;

const ColumnText = styled.div`
  font-size: 16px;
  ${media.small`
    font-size: 12px;
  `}
`;

const AnswerText = styled.div`
  white-space: pre-wrap;
  word-break: break-word;
  padding: 10px;
  background: #f5f5f5;
  border-radius: 4px;
  max-height: 60vh;
  overflow-y: auto;
`;

const ChartContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  min-height: 500px;
  // margin: 12px 0;
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  ${media.small} {
    height: 220px;
    margin: 6px 0;
  }
  @media not all and (min-resolution: 0.001dpcm) {
     @supports (-webkit-appearance: none) {
       height: 340px;
      -webkit-overflow-scrolling: touch;
       overscroll-behavior: contain;
     }
    }
`;


const ModalContainer = styled.div`
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;

  ${media.small`
    width: 90%;
    margin: 20% auto;
  `}
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  h2 {
    font-size: 24px;
  }
`;

const ModalBody = styled.div`
  margin-top: 20px;
  max-height: 60vh;
  overflow-y: auto;
  flex-direction: column;
  gap: 20px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
const CloseButton = styled.div`
  cursor: pointer;
  ${media.small`
    font-size: 20px;
  `}
`;
const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 10px 0;
  
  .ant-pagination {
    font-size: 14px;
  }
  
  ${media.small`
    .ant-pagination-item,
    .ant-pagination-options {
      margin: 0 2px;
    }
  `}
`;

const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  width: 30%;
  margin-right: -48px;
  padding: 10px 0 0px;
  
  ${media.small`
    width: 77%;
    height: 50px;
    font-size: 12px;
    margin-right: -23px;
    margin-top: -20px;
    padding: 10px 0 0px;
  `}
`;
const FiltersContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ChartTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin: 20px 0 10px;
  text-align: left;
`;
const InsightText = styled.div`
  margin-top: 15px;
  font-size: 14px;
  color: #374151;
  text-align: left;
   strong {
    font-weight: 600;
    color: #111827;
  }
  br {
    display: block;
    content: "";
    margin-bottom: 10px;
  }
  ul {
    padding-left: 20px;
    margin: 8px 0;
  }
  li {
    margin-bottom: 6px;
    list-style-type: disc;
  }
  ${media.small`
    font-size: 12px;
  `}
`;